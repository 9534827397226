// 系统项目管理
.projectMgt-title {
    padding-bottom: 8px;
    .projectMgt-wrap {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .projectMgt-item {
        width: 30%;
      }
      .projectMgt-item-btn {
        margin-bottom: 8px;
        margin-left: 15px;
      }
    }
}

.add-projectMgt {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
    .add-projectMgt-btn {
      margin-left: 15px;
    }
}
.modalStyle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  .asterisk {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
  }
  .modalStyleName {
    width: 100px;
    text-align: right;
    padding-right: 10px;
  }
}

.site-tree-search-value {
  color: #f50;
}

// tree样式
.tree-projectMgt {
  .ant-tree {
    padding: 0 20px;
    .ant-tree-node-content-wrapper {
      width: 100%;
      .levelSty {
        width: 100%;
        display: flex;
        // justify-content: space-between;
        .levelSty-span {
           min-width: 20%;
           max-width: 200px;
           overflow:hidden;
           text-overflow:ellipsis;
           white-space: nowrap;
        }
        .levelSty-left {
          width: 26%;
          justify-content: space-between;
          display: flex;
          .levelSty-left-id {
            display: inline-block;
            width: 80px;
          }
          .levelSty-left-span {
            flex: 1;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }
        .levelSty-right {
          width: 55%;
          display: flex;
          justify-content: space-between;
          .levelSty-right-btn {
            display: flex;
            width: 45%;
            justify-content: space-between;
            a{
              margin-right: 5px;
            }
          }
          .levelSty-right-Switch {
            // width: 200px;
            display: flex;
            justify-content: center;
          }
          .levelSty-right-Flex {
            width: 200px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}

// 设置行业
.setIndustry {
  .type-add {
    width: 100%;
    border: 1px dashed #ccc;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .setCloseBtn {
    width: 820px;
    height: 60px;
    background-color: #fff;
    position: fixed;
    bottom: 0px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 50px;
    border-top: 1px solid #f0f2f5;
  } 
  .setShortCloseBtn {
    width: 640px;
    height: 60px;
    background-color: #fff;
    position: fixed;
    bottom: 0px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 50px;
    border-top: 1px solid #f0f2f5;
  }
}

// 厂商ID查询
.vendorstry {
  .setVendorstryBtn {
    width: 880px;
    height: 50px;
    background-color: #fff;
    border-top: 1px solid #ccc;
    position: fixed;
    bottom: 0px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 50px;
  } 
  .vendorwrap {
    display: flex;
    align-items: center;
    margin: 0 0 20px 50px;
  }
  .vendor-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
}

// 负责人
.setHead {
  .ant-select{
    width: 100%;
  }
}

// 订单资料字段
.bizOrderFields {
  height: 80px;
  // border-bottom: 1px solid #ccc;
}
.borderLine {
  height: 1px;
  background: #f0f2f5;
  width: calc(100% + 40px);
  margin-left: -20px;
}
.bizOrder-table {
  margin-top: 28px;
  margin-bottom: 50px;
}
.bizOrder-btn {
  height: 64px;
  width: 100%;
  margin-left: -50px;
  background-color: #fff;
  border-top: 1px solid rgba(255,255,255,1);
  box-shadow:0px 2px 13px 0px rgba(0, 0, 0, 0.11);
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 999;
}

.tree-projectMgt .ant-tree.ant-tree-show-line li:not(:last-child)::before{
  border-left: none;
}
.tree-projectMgt .anticon-file{
  display: none;
}

// 供应商管理
.ant-inputTwo {
  width: 120% !important;
}
.industrySty {
  .ant-tree-child-tree {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    // .ant-tree-treenode-switcher-open {
    //   width: 30%;
    // }
  }
  .ant-tree-child-tree > li:first-child {
    padding-top: 0
  }
  .ant-tree li {
    padding: 0
  }
  .industryTopBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
}

.setIndustry {
  .setIndustry-wrap {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 50px;
    .setIndustry-title {
      font-size: 16px;
      font-weight: 800;
    }
    .setIndustry-item {
      display: flex;
      align-items: center;
      margin-right: 15px;
  
      > label {
        width: 70px;
        // text-align: right;
        margin-right: 5px;
      }
    }
  }
}

// 项目设置
.filter-input {
   width: 280px;
}
.topInput {
  display: flex;
  // align-items: center;
  justify-content: flex-start;
  .ant-form-item {
    margin-bottom: 0;
    width: 120px;
  }
}
.departmentSty {
  margin-top: 20px;
  padding-right: 100px;
  .ant-form-item-control {
    margin-left: 0;
  }
}
.supplierSty {
  .ant-checkbox-wrapper {
    margin-left: 0;
  }
}

// 折扣配置
.businessStySea {
  // margin-top: -80px;
  width: 100%;
  position: absolute;
  top: -140px;
}
.businessStyLine {
  height: 10px;
  background: #f0f2f5;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-top: 40px;
}

.businessStyLines {
  margin-top: 20px;
}

.discountBSty {
  margin-top: 130px;
  .ant-tabs {
    overflow: visible;
  }
}

.businessSty {
  display: flex;
  justify-content: flex-end;
  > Button {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

.businessForm {
  .lineOfsty {
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
    .lineOfstyFormItem {
      width: 50%;
    }
    .ant-form-item-label {
      overflow: visible;
    }
  } 
  .ant-row {
  display: flex;
  justify-content: flex-start;
  }
  .ant-select-selection {
    width: 580px;
  }
  .businessFormTop {
    width: '100%';
    margin-left: 56px;
  }
}
.setBusinessCloseBtn {
  width: 820px;
  height: 60px;
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 50px;
  border-top: 1px solid #f0f2f5;
} 
.setIndustryCloseBtn{
  width: 700px;
}

// 公司配置详情
.detailLine {
  height: 1px;
  background: #f0f2f5;
  width: calc(100% + 40px);
  margin: 10px 0 10px -20px;
}
.detailText {
  margin-bottom: 5px;
}
.detailTextTitle {
  font-size: 12px;
}
.infoline {
  height: 18px;
  background: #f0f2f5;
  width: calc(100% + 40px);
  margin: 20px 0 0 -20px;
}
.detailTitleSty {
  margin: 16px 0 10px 10px;
  font-weight: 700;
}
.addDiscountCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
//行业分组管理
.configMsgIndustryForm{
  .ant-tree li ul li{
    display: inline-block;
    white-space: normal;
  }
}
.treeNodeBox{
  position: relative;
  .treeNodeBoxSel{
    position: absolute;
    right: -10px;
    top: 0;
    .ant-tree-node-content-wrapper{
      color: #1890ff;
    }
  }
}
.search-industry-wrap form{
  > .form-box {
    .ant-form-item{
      width: 25%;
    }
  }
}

// .projectmgt-setmain-form{
//   position: relative;
//   .btn-group {
//     position: absolute;
//     top: 600px;
//     right: 0px;
//     width: 100%;
//   }
// }